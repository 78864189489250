import React, { FC, useEffect, useState } from 'react';
import PriceSimulator from 'components/molecules/pricing/pricingEntrepreneur/PriceSimulator';
import PricingEntrepreneurHeader from 'components/molecules/pricing/pricingEntrepreneur/PricingEntrepreneurHeader';
import PricingEntrepreneurRanges from 'components/molecules/pricing/pricingEntrepreneur/PricingEntrepreneurRanges';
import {
    useCreateEntrepreneurPriceList,
    useDeleteEntrepreneurPriceList,
    useGetEntrepreneurPriceLists,
    useGetEntrepreneurSimulator,
    useSetEntrepreneurChosenContractorPriceList,
    useSetEntrepreneurPriceList,
} from 'api/queries/pricingEntrepreneur';
import { CreateEntrepreneurPriceListParam, SetEntrepreneurPriceListReq } from 'models/api/pricingEntrepreneur';
import { useGetCompanyDetails } from 'api/queries/management';
import { ChosenConstructionPriceList } from 'models/api/common';
import { CompanyType } from 'models/api/management';
import { enqueueSnackbar } from 'notistack';
import { ToastType } from 'components/atoms/Toast';
import texts from 'texts.json';

const PricingEntrepreneur: FC = () => {
    const { data, refetch: getEntrepreneurPriceLists } = useGetEntrepreneurPriceLists();
    const { mutate: mutateSetEntrepreneurPriceList } = useSetEntrepreneurPriceList();
    const { mutate: mutateCreateEntrepreneurPriceList } = useCreateEntrepreneurPriceList();
    const { data: entrepreneurSimulatorData, mutate: mutateGetEntrepreneurSimulator } = useGetEntrepreneurSimulator();
    const { mutate: setChosenPriceList } = useSetEntrepreneurChosenContractorPriceList();
    const { mutate: mutateDeleteEntrepreneurPriceList } = useDeleteEntrepreneurPriceList();
    const { data: companyDetails, refetch } = useGetCompanyDetails(true);

    const [priceListType, setPriceListType] = useState<ChosenConstructionPriceList | undefined>();
    const [isEdit, setIsEdit] = useState<string>('');
    const [closeSimulator, setCloseSimulator] = useState<boolean>(false);

    const company = companyDetails?.data?.item;
    const isConstractor = company?.type.some((item) => item === CompanyType.CONSTRACTOR);

    const setEntrepreneurPriceList = (item: SetEntrepreneurPriceListReq) => {
        mutateSetEntrepreneurPriceList(item, {
            onSuccess: () => {
                setIsEdit('');
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
                getEntrepreneurPriceLists();
                setCloseSimulator(true);
            },
        });
    };

    const createEntrepreneurPriceList = (item: CreateEntrepreneurPriceListParam) => {
        mutateCreateEntrepreneurPriceList(item, {
            onSuccess: () => {
                setIsEdit('');
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
                getEntrepreneurPriceLists();
                setCloseSimulator(true);
            },
        });
    };

    const deleteEntrepreneurPriceList = (id: string) => {
        mutateDeleteEntrepreneurPriceList(id, {
            onSuccess: () => {
                enqueueSnackbar(texts.common.successText || '', {
                    variant: 'toast',
                    type: ToastType.SUCCESS,
                });
                getEntrepreneurPriceLists();
                setCloseSimulator(true);
            },
        });
    };

    const getEntrepreneurSimulator = (value: string) => {
        setCloseSimulator(false);
        mutateGetEntrepreneurSimulator(value);
    };

    const onPriceListTypeHandler = (value: ChosenConstructionPriceList) => {
        setPriceListType(value);
        setCloseSimulator(true);
        if (company?.item_id) {
            setChosenPriceList(
                { item: value, itemId: company?.item_id },
                {
                    onSuccess: () => {
                        refetch();
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (company) {
            setPriceListType(company.chosen_construction_price_list);
        }
    }, [company]);

    return (
        <>
            <PricingEntrepreneurHeader
                isConstractor={isConstractor}
                priceListType={priceListType}
                onPriceListType={onPriceListTypeHandler}
            />
            {data?.data?.items && (
                <>
                    <PricingEntrepreneurRanges
                        items={data.data.items}
                        setEntrepreneurPriceList={setEntrepreneurPriceList}
                        createEntrepreneurPriceList={createEntrepreneurPriceList}
                        deleteEntrepreneurPriceList={deleteEntrepreneurPriceList}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                    />
                    <PriceSimulator
                        closeSimulator={closeSimulator}
                        getEntrepreneurSimulator={getEntrepreneurSimulator}
                        simulatorData={entrepreneurSimulatorData?.item}
                    />
                </>
            )}
        </>
    );
};

export default PricingEntrepreneur;
